import React from 'react';
import { Flex, Image } from '@chakra-ui/core';
import { cloudfrontBaseUrl } from '../../lib/consts';
import { H1, H4 } from '@typography';
import ContentContainer from '../../components/ContentContainer';
import { DesktopOnly, MobileOnly } from '../../layouts/Footer';
import GradientBackground from '../../assets/ZetaPlusBgDesktop.png';
import GradientBackgroundMobile from '../../assets/ZetaPlusBgMobile.png';
import HeroDesktop from '../../assets/ZetaPlusHeroDesktop.png';
import HeroMobile from '../../assets/ZetaPlusHeroMobile.png';

const TopSection = () => {
  const Content = () => {
    return (
      <>
        <ContentContainer>
          <Flex
            className="zeta-plus-overview"
            width="100%"
            justifyContent="center"
            direction="column"
            pt={[0, 16]}
          >
            <Flex
              className="zeta-plus-hero"
              pt={[24, 18]}
              align="center"
              direction="column"
            >
              <DesktopOnly>
                <H1 center>Introducing Zeta+</H1>

                <H4 position="relative" zIndex={3} center as="h2" mt={8}>
                  Premium feature add-ons to help your family thrive
                </H4>
                <Flex justify="center">
                  <Image
                    mt={52}
                    style={{ maxHeight: '525px' }}
                    position="relative"
                    src={HeroDesktop}
                    alt="zeta in app premium features"
                  />
                </Flex>
              </DesktopOnly>

              <MobileOnly>
                <H1>
                  Introducing
                  <br />
                  Zeta+
                </H1>
                <H4 center as="h2" pt="22px">
                  Premium feature add-ons
                  <br />
                  to help your family thrive
                </H4>
                <br />
                <br />

                <div
                  style={{
                    marginTop: '12px',
                  }}
                >
                  <Image zIndex={0} src={HeroMobile} alt="zeta in app premium features" />
                </div>
              </MobileOnly>
            </Flex>
          </Flex>
        </ContentContainer>
      </>
    );
  };

  return (
    <>
      <DesktopOnly>
        <div
          style={{
            backgroundImage: `url(${GradientBackground})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <Content />
        </div>
      </DesktopOnly>
      <MobileOnly>
        <div
          style={{
            backgroundImage: `url(${GradientBackgroundMobile})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <Content />
        </div>
      </MobileOnly>
    </>
  );
};

export default TopSection;
